import * as React from "react"
import { Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Slider from 'react-slick';
import ContactCardServices from "../Home/ContactCard/ContactCardServices"
import _ from "lodash"
import "../FeaturedNewHome/FeaturedNewHome.scss"
import { graphql, useStaticQuery } from "gatsby";


const MarketStatistic = (props) => {

    const property = useStaticQuery(graphql`
        query GetMarketStatistics {
            glstrapi {    
                 marketStatistics(sort:"id:desc") {
                    data {
                        attributes {
                            Title
                            URL
                            Contents
                            type
                            Title_images {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            embedded_url                         
                        }
                    }
                }    
            }
        }
    `)

  const featuredetails =  property?.glstrapi?.marketStatistics?.data

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    mobileFirst: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
    ]
  }

  return (
    

    featuredetails &&
    <div className="featured-new-projects-section market-analysis project-analysis propertyServices market-statistics">
      <div className="featured-new-container">
        <Container>
        {/* <h2 className="new-project-title text-center">{props?.chart_list?.chart_list_name.replace("_"," ")}</h2>        */}
        <div className="featured-new-projects-slider-section">

            <Slider {...settings} >
            {
                !_.isEmpty(featuredetails) && featuredetails?.map((item, index) => {
                return ( 
                    <ContactCardServices
                    cardImg={item?.attributes?.Title_images?.data?.attributes}
                    //cardTitle={item?.attributes?.Title}
                    cardUrl={`/about/market-statistics/${item?.attributes?.URL}`}
                    type={item?.attributes?.type}
                    //cardContents={item?.service_contents}
                    //imagetransforms={item.ggfx_results}
                    ggfxNew={true}
                    />
                )
                })
            }
            </Slider>
            </div>       
        <div className="text-center view-more-cta">
          <Link to={`/about/market-statistics/`} className="btn btn-primary">
            <span>{`View more`}</span>
          </Link>
        </div>
        </Container>
      </div>
    </div>
  )
}

export default MarketStatistic