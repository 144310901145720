import * as React from "react"
import { Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Slider from 'react-slick';
import ContactCard from "../Home/ContactCard/ContactCard"
import _ from "lodash"
import "../FeaturedNewHome/FeaturedNewHome.scss"
import { ExploreProperties } from "../../queries/common_use_query"

const ExclusiveProperties = (props) => {

  const featuredetails = props?.details

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    mobileFirst: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
    ]
  }
  //console.log("data",featuredetails)
  return (
    
   
    featuredetails &&
    <div className="featured-new-projects-section market-analysis project-analysis exclusiveProperties">
      <div className="featured-new-container">
        <Container>
        <h2 className="new-project-title text-center">{featuredetails?.title}</h2>
        {featuredetails?.content &&
               <p className="tab-desc">{featuredetails?.content}</p>
        }
        <div className="featured-new-projects-slider-section">

          <Slider {...settings} >
            {
              !_.isEmpty(featuredetails?.propertyLists) && featuredetails?.propertyLists?.map((item, index) => {
                let { loading, error, data } = ExploreProperties(item?.property_title, 1000);
                let property_count = data?.properties?.data?.length + data?.newProjects?.data?.length
                return ( 
                  <ContactCard
                    cardImg={item?.property_image}
                    cardTitle={item?.property_title}
                    date=""
                    cardTag=""
                    imagetransforms={item.ggfx_results}
                    ggfxNew={true} 
                    cardUrl={item.property_url}
                    counter={property_count}
                  />
                )
              })
            }
          </Slider>
        </div>
        <div className="text-center view-more-cta">
          <Link to={`https://www.thepearlgates.com/about/our-communities/`} className="btn btn-primary">
            <span>{`View All Areas`}</span>
          </Link>
        </div>
        </Container>
      </div>
    </div>
  )
}

export default ExclusiveProperties